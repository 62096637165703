<template>
	<div class="main-contents point">
		<div class="mem_container">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div class="title-txt">적립포인트 &gt; 내 적립포인트<br/>
						<p>총<span class="prj_ct">{{pageInfo.totalRecordCount}}</span>건의 적립내용이 있습니다.</p>
					</div>
				</div>
				<div class="point_banner">
					<img src="/images/point_banner.png" alt="포인트관리"/>
					<div class="image_box">
						<ImageComp :src="'/api/mem/myp/corpImageDown?corpSeq=' + data.corpSeq" defaultSrc="COMPANY" />
					</div>
					<div class="customer">{{data.corpNm}}</div>
					<div class="point">
						<span>'{{data.corpNm}}'</span>사의 적립포인트는<br/>
						<span class="pt">{{data.pointSum | number}}</span> <img src="/images/admin/point_m.png" alt="포인트"/> 입니다.
					</div>
				</div>
				<div class="contents-body">
					<div class="filter">
						<div class="filter_box">
							<ul>
								<li><img src="/images/icon_filter.png" alt="필터" :class="{filter : input.orderGubun == 'regiDsc'}"/><p @click="changeOrder('regiDsc')">최근 등록 순</p><span>ㅣ</span></li>
								<li><img src="/images/icon_filter.png" alt="필터" :class="{filter : input.orderGubun == 'incDsc' }"/><p @click="changeOrder('incDsc' )">적립포인트 많은 순</p><span>ㅣ</span></li>
								<li><img src="/images/icon_filter.png" alt="필터" :class="{filter : input.orderGubun == 'incAsc' }"/><p @click="changeOrder('incAsc' )">적립포인트 적은 순</p><span>ㅣ</span></li>
								<li><img src="/images/icon_filter.png" alt="필터" :class="{filter : input.orderGubun == 'decDsc' }"/><p @click="changeOrder('decDsc' )">차감포인트 많은 순</p><span>ㅣ</span></li>
								<li><img src="/images/icon_filter.png" alt="필터" :class="{filter : input.orderGubun == 'decAsc' }"/><p @click="changeOrder('decAsc' )">차감포인트 적은 순</p></li>
							</ul>
						</div>
					</div>
					<!-- 적립포인트 리스트 -->
					<div class="Board type3 point">
						<table class="Board_type3">
							<colgroup>
								<col width="6%">
								<col width="18%">
								<col width="34%">
								<col width="8%">
								<col width="22%">
								<col width="8%">
							</colgroup>
							<thead>
								<tr>
									<th>NO</th>
									<th>적립구분</th>
									<th>적립내용</th>
									<th>날짜</th>
									<th>적립포인트</th>
									<th>확정여부</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="!list || list.length == 0"><td colspan="8" class="none">적립된 포인트가 없습니다!</td></tr>
								<tr v-for="(item, idx) in list" :key="idx" v-else>
									<td class="score">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (idx + 1)}}</td>
									<td class="score">{{item.pointSaveItemNm}}</td>
									<td><img src="/images/admin/new.png" alt="알림" v-if="isNew(item)"/> {{item.pointSaveDtlCont || item.pointSaveItemDesc}}</td>
									<td class="score">{{item.regDate | date('yyyy. MM. dd')}}</td>
									<td class="score amount">{{item.pointAmt | number}} <img src="/images/admin/point_s.png" alt="적립포인트"/></td>
									<td class="score cf">
										<p class="cf" v-if="item.pointSaveDivCd == '01'">+ 적 립</p>
										<p class="ud" v-else-if="item.pointSaveDivCd == '02'">- 취 소</p>
										<p v-else-if="item.pointSaveDivCd == '03'">- 지 급</p>
										<p class="ud" v-else-if="item.pointSaveDivCd == '04'">- 차 감</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					
					<!-- 페이징부분 -->
					<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>

					<!-- 포인트 확정 -->
					<div class="point_cf">
						<p>확정</p><span>{{data.pointSaveSum | number}}</span>
					</div>
					<div class="point_cf pay">
						<p>지급 포인트</p><span>-{{data.pointPaySum | number}}</span>
						<p>차감 포인트</p><span>-{{data.pointCancelSum + data.pointDecSum | number}}</span>
					</div>
					<div class="point_cf total">
						<p>현재</p><span>{{data.pointSum | number}}</span><img src="/images/admin/point_m.png" alt="포인트"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PagingComp from '@/components/PagingComp.vue';
export default {
	components : { PagingComp },
	data() {
		return {
			input: {
				orderGubun: 'regiDsc',
				pageIndex : '1',
			}, // 검색조건
			data: {},
			list : [],
			pageInfo : {},
		};
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {
			this.$.httpPost('/api/mem/pit/getCorpPoint', this.input)
				.then(res => {
					this.data = res.data;
					this.list = res.data.list;
					this.pageInfo = res.data.pageInfo;
				}).catch(this.$.httpErrorCommon);
		},
		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},
		changeOrder(orderGubun){
			//console.log('changeOrder', orderGubun, this.input.orderGubun)
			if(orderGubun != this.input.orderGubun){
				this.input.orderGubun = orderGubun;
				this.goPage(1);
			}
		},

		isNew(item){
			return new Date().add(-1, 'day').format('yyyyMMdd') <= item.regDate;
		},
	},
};
</script>